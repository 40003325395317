.checkerBoard {
  background-image: -moz-linear-gradient(45deg, #ddd 25%, transparent 25%),
    -moz-linear-gradient(-45deg, #ddd 25%, transparent 25%),
    -moz-linear-gradient(45deg, transparent 75%, #ddd 75%),
    -moz-linear-gradient(-45deg, transparent 75%, #ddd 75%);
  background-image: -webkit-gradient(
      linear,
      0 100%,
      100% 0,
      color-stop(0.25, #ddd),
      color-stop(0.25, transparent)
    ),
    -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, #ddd), color-stop(0.25, transparent)),
    -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.75, transparent), color-stop(0.75, #ddd)),
    -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.75, transparent), color-stop(0.75, #ddd));

  -moz-background-size: 10px 10px;
  background-size: 10px 10px;
  -webkit-background-size: 10px 10px; /* override value for shitty webkit */

  background-position: 0 0, 5px 0, 5px -5px, 0px 5px;
}
